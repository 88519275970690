var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PopUp',{attrs:{"show":_vm.modal},on:{"hideModal":_vm.hideModal}},[_c('ValidationObserver',{ref:"observer",staticClass:"memoriam",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('header',{staticClass:"memoriam__header"},[_c('h3',{staticClass:"memoriam__header_title"},[_vm._v(_vm._s(_vm.memoriam.id ? "Edit" : "Add")+" Lives Well Lived")]),_c('div',{staticClass:"memoriam__header_actions"},[(_vm.item)?_c('BaseButton',{staticClass:"button--sm button--outline button--uppercase",attrs:{"text":"Delete"},on:{"click":function($event){return _vm.$emit('removeItem', _vm.memoriam)}}}):_vm._e(),_c('BaseButton',{staticClass:"button--sm button--green button--uppercase",attrs:{"text":"Save Changes","loading":_vm.changesLoading,"disabled":invalid || !_vm.changed},on:{"click":_vm.saveLooseThread}})],1)]),_c('div',{staticClass:"form__row"},[_c('ValidationProvider',{staticClass:"form__row_col",attrs:{"tag":"div","rules":{ required: true, min: 2, max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInput',{attrs:{"placeholder":"Name","required":true,"validateError":errors[0] || _vm.serverError.title,"element":_vm.memoriam.title},on:{"input":function($event){_vm.memoriam.title = $event}},model:{value:(_vm.memoriam.title),callback:function ($$v) {_vm.$set(_vm.memoriam, "title", $$v)},expression:"memoriam.title"}})]}}],null,true)})],1),_c('div',{staticClass:"form__row"},[_c('ValidationProvider',{staticClass:"form__row_col form__row_col--side",attrs:{"tag":"div","rules":{ required: true, min: 4, max: 4, more_then: _vm.memoriam.death_year }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseNumberInput',{attrs:{"placeholder":"Birth Year","required":true,"validateError":errors[0] || _vm.serverError.birth_year,"element":_vm.memoriam.birth_year},on:{"input":function($event){_vm.memoriam.birth_year = $event}},model:{value:(_vm.memoriam.birth_year),callback:function ($$v) {_vm.$set(_vm.memoriam, "birth_year", $$v)},expression:"memoriam.birth_year"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"form__row_col form__row_col--side",attrs:{"tag":"div","rules":{ required: true, min: 4, max: 4, less_then: _vm.memoriam.birth_year }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseNumberInput',{attrs:{"placeholder":"Death Year","required":true,"validateError":errors[0] || _vm.serverError.death_year,"element":_vm.memoriam.death_year},on:{"input":function($event){_vm.memoriam.death_year = $event}},model:{value:(_vm.memoriam.death_year),callback:function ($$v) {_vm.$set(_vm.memoriam, "death_year", $$v)},expression:"memoriam.death_year"}})]}}],null,true)})],1),_c('div',{staticClass:"form__row"},[_c('div',{staticClass:"form__row_col"},[_c('Editor',{attrs:{"placeholder":"Description","value":_vm.memoriam.description},on:{"input":function($event){_vm.memoriam.description = $event}},model:{value:(_vm.memoriam.description),callback:function ($$v) {_vm.$set(_vm.memoriam, "description", $$v)},expression:"memoriam.description"}})],1)]),_c('div',{staticClass:"form__row"},[_c('ValidationProvider',{staticClass:"form__row_col form__row_col--side",attrs:{"tag":"div","rules":{ max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInput',{attrs:{"placeholder":"Button Text","validateError":errors[0],"element":_vm.memoriam.button_text},on:{"input":function($event){_vm.memoriam.button_text = $event}},model:{value:(_vm.memoriam.button_text),callback:function ($$v) {_vm.$set(_vm.memoriam, "button_text", $$v)},expression:"memoriam.button_text"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"form__row_col form__row_col--side",attrs:{"tag":"div","rules":{ max: 255 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('BaseInput',{attrs:{"placeholder":"Button URL","validateError":errors[0],"element":_vm.memoriam.button_url},on:{"input":function($event){_vm.memoriam.button_url = $event}},model:{value:(_vm.memoriam.button_url),callback:function ($$v) {_vm.$set(_vm.memoriam, "button_url", $$v)},expression:"memoriam.button_url"}})]}}],null,true)})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }